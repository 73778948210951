<template>
  <div class="container">
    <div class="main-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.container {
  padding: 20px;
  .main-content {
    padding: 20px;
    background: #fff;
    border: 1px solid #ebeef5;
    transition: 0.3s;
    min-height: calc(100vh - 200px);
  }
}
</style>
